import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, BrowserRouter } from "react-router-dom";

// import "../src/src/app2/assets/vendor/css/rtl/core.css";
// import "../src/src/app2/assets/vendor/css/rtl/theme-default.css";
// import "../src/src/app2/assets/vendor/css/pages/front-page.css";
// import "../src/src/app2/assets/vendor/css/pages/page-profile.css";
// import "../src/src/app2/assets/assets/vendor/libs/swiper/swiper.css"

// import "../src/src/app2/assets/assets/vendor/libs/swiper/swiper.js"
// import "../src/src/app2/assets/vendor/css/pages/page-auth.css";
// import "../src/src/app2/assets/js/front-page-landing.js"
// import "../src/src/app2/assets/assets/vendor/css/pages/front-page-landing.css"
// import "../src/src/app2/assets/assets/vendor/css/pages/front-page.css"
// import "../src/src/app2/assets/assets/vendor/css/pages/front-page-pricing.css"
import 'primereact/resources/themes/saga-blue/theme.css'; // PrimeReact theme
import 'primereact/resources/primereact.min.css'; // PrimeReact core CSS
import 'primeicons/primeicons.css'; // PrimeIcons CSS


import "./assets/css/demo.css";

import "./assets/vendor/css/rtl/core.css";

import "./assets/vendor/css/pages/front-page.css";

import "./assets/vendor/css/pages/page-profile.css";
import "./assets/vendor/css/pages/front-page-landing.css";

import "./assets/vendor/css/pages/page-auth.css";
import "./assets/vendor/libs/animate-css/animate.css";
import "./assets/vendor/css/rtl/theme-default.css";

import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "./assets/vendor/libs/typeahead-js/typeahead.css";

import "./assets/vendor/libs/@form-validation/form-validation.css";
import "./assets/vendor/libs/apex-charts/apex-charts.css";
import "./assets/vendor/libs/node-waves/node-waves.css";



// import "./assets/vendor/libs/swiper/swiper.js"
import "./assets/vendor/css/pages/page-auth.css";
// import "./assets/js/front-page-landing.js"
import "./assets/vendor/css/pages/front-page-landing.css"
import "./assets/vendor/css/pages/front-page.css"
import "./assets/vendor/css/pages/front-page-pricing.css"
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
