import React, { useState, useEffect } from "react";
import { Link ,useNavigate} from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
const StudentHeader = () => {
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [currentTime, setCurrentTime] = useState(new Date());
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
const navigate=useNavigate();

 

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const monthAbbreviations = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sept", // Custom abbreviation
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const formatTime = (date) => {
    const optionsDate = { weekday: "short", day: "numeric" };
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };

    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const month = monthAbbreviations[date.getMonth()];
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });
    
    const formattedDate = `${weekday}, ${day} ${month}`;
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatTime(currentTime);
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.clear();
    // Optionally, redirect to login page or perform other actions after logout
    navigate("/commonlogin");
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseModal();
    // Optionally, redirect to login page or perform other actions after logout
  };
  return (
    <div>
      <nav
        className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="container-xxl d-flex justify-content-between align-items-center">
          {/* Left Section: Logo and TradeMirror */}
          <div className="d-flex align-items-center">
            <div className="avatar me-2">
              <img
                src={`${process.env.PUBLIC_URL}/favicon.png`}
                alt="autoprofito Logo"
                className="w-px-40 h-auto rounded-circle"
              />
            </div>
            <span
              className="app-brand-text demo menu-text fw-semibold"
              style={{ fontSize: "22px" }}
            >
             StockSync
            </span>
          </div>

          {/* Center Section: Current Time */}
          <div className="d-none d-xl-block">
            <span className="text-secondary">
             {formattedDate}
            </span>
            <br></br>

            <strong className="fs-4">{formattedTime}</strong>
          </div>
          {/* Right Section: User Info and Profile Menu */}
          <div className="d-flex align-items-center">
            <div className="text-end  text-capitalize me-3">
              <h6 className="mb-0" style={{ fontSize: "16px" }}>
                {userName}
              </h6>
              {/* <div className="text-center"> */}
              {/* Inline style for badge */}
              <span className=" badge rounded-pill text-capitalize bg-label-info" style={{ fontSize: "14px" }}>
                {userRole}
              </span>
              {/* </div> */}
            </div>

            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                  className="nav-link dropdown-toggle hide-arrow p-0"
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                >
                  <div className="w-px-40 h-auto rounded-circle position-relative">
                    <div className="d-flex justify-content-center align-items-center border border-info border-3  rounded-circle bg-label-info avatar-lg ">
                      <span className="avatar-initial rounded-circle bg-label-info">
                        {" "}
                        {userName &&
                          userName
                            .split(" ")
                            .filter(
                              (word, index) =>
                                index === 0 ||
                                index === userName.split(" ").length - 1
                            )
                            .map((word) => word.charAt(0).toUpperCase())
                            .join("")}
                      </span>
                    </div>
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end mt-3 py-2">
                  {/* <li>
                    <a className="dropdown-item" href="pages-account-settings-account.html">
                      <div className="d-flex align-items-center">
                        <div className="avatar me-2">
                          <img src={mirrorLogo} alt="Profile" className="w-px-40 h-auto rounded-circle" />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0 small">{toTitleCase(userName)}</h6>
                          <small className="text-muted">{toTitleCase(userRole)}</small>
                          
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li> */}
                  <li>
                    <Link to="/app2/student_profile" className="dropdown-item">
                      <i className="ri-user-line ri-22px me-2"></i>
                      <span className="align-middle">Profile</span>
                    </Link>
                  </li>

                  <li>
                    <div className="d-grid px-4 pt-2 pb-1">
                      <div
                       
                       onClick={handleShowModal}
                        className="dropdown-item btn btn-danger d-flex align-items-center"
                      >
                        <i className="ri-logout-box-r-line ri-lg me-1"></i>{" "}
                        <span className="align-middle">Logout</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

         
        </div>
      </nav>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to logout?
        </Modal.Body>
        <Modal.Footer>
          
          <div className="d-flex justify-content-between w-100">
                            <button
                              onClick={handleCloseModal}
                              className="btn btn-outline-secondary rounded-pill btn-sm "
                            >
                              <i className="ri-close-large-line me-2"></i>
                              <span>Cancel</span>
                            </button>
                            <button
                              onClick={handleConfirmLogout}
                              
                              className="btn btn-danger rounded-pill btn-sm"
                            >
                                <i className="ri-logout-box-r-line  me-2"></i>
                            Logout
                            </button>
                          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StudentHeader;
