
import React, { useEffect, useState ,useRef} from "react";
import SubHeaderS from "./SubHeaderS";
import StudentHeader from "./StudentHeader";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/img/avatars/1.png";
import Footer from "../../../component/Footer"
import config from "../../../component/config";
import { Toast } from 'primereact/toast';
import axios from "axios";
const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [isTradingPowerEditable, setIsTradingPowerEditable] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({}); 
  const navigate=useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const authToken = localStorage.getItem("authToken");
       
        if (!userId) {
          console.error("User ID not found in localStorage");
          return;
        }
  
        if (!authToken) {
          console.error("Access token not found in localStorage");
          navigate("/commonlogin")
          return;
        }
  
        const response = await fetch(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${authToken}`
            },
            body: JSON.stringify({ user_id: userId }),
          }
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
  
        if (data.st === 1) {
          setUserData(data.user_details); // Corrected from data.user to data.user_details
          setFormData(data.user_details); // Initialize formData with the fetched user data
        } else {
          console.error("Failed to fetch user profile:", data.message);
        }
      } catch (error) {
      
          console.error("Error fetching user profile:", error);
          // Check for 401 Unauthorized error
          if (error.response && error.response.status === 401) {
            console.error("Unauthorized access, redirecting to common login screen");
            navigate('/commonlogin'); // Navigate to common login screen on 401 error
          }
        }
      };
  
    fetchUserProfile();
  }, []); // Ensure the dependency array is empty
  
  


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "lot_size_limit") {
      if (/^\d*$/.test(value)) {
        // Only allow digits
        if (parseInt(value, 10) > 5000) {
          setError("Maximum limit is 5000");
          setFormData({
            ...formData,
            [name]: 5000,
          });
        } else {
          setError("");
          setFormData({
            ...formData,
            [name]: value,
          });
        }
      } else {
        setError("Only digits are allowed");
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      // If the "Trading Power" field is edited, set it as editable
      if (name === "tradingPower") {
        setIsTradingPowerEditable(true);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
         const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
        console.error("Access token not found in localStorage");
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Access token not found. Please log in again.', life: 3000 });
        setLoading(false);
        navigate("/commonlogin")
        return;
      }
  
      const response = await axios.put(
        `${config.apiDomain}/api/common/save_profile_details`,
        {
          user_id: localStorage.getItem('userId'),
          email: formData.email,
          mobile: formData.mobile,
          name: formData.name,
          lot_size_limit: formData.lot_size_limit
        },
        {
          headers: {
            "Authorization": `Bearer ${authToken}`
          }
        }
      );
  
       
    
      if (response.data && response.data.st === 1) {
        setUserData(formData);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: formatMessage(response.data.msg) || "Success!",
          className: "text-start",
          life: 3000,
        });

        
      } else if (response.data && response.data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail:
            formatMessage(response.data.msg) ||
            "Warning: Please check the input.",
          life: 3000,
        });
      } else if (
        response.data &&
        (response.data.st === 3 || response.data.st === 4)
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: formatMessage(response.data.msg) || "An error occurred.",
          life: 3000,
        });
      } else {
        
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unexpected response status",
          life: 3000,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response?.data?.msg
          ? formatMessage(error.response.data.msg)
          : String(error.message || "Failed to fetch data");

        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
          className: "text-start",
        });
      }
    } finally {
      setLoading(false);
    }
  };



  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };
  const handleBrokerInformation = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
        console.error("Access token not found in localStorage");
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Access token not found. Please log in again.', life: 3000 });
        setLoading(false);
        // Navigate to login component
        navigate('/commonlogin');
        return;
      }
  
      const response = await axios.put(
        `${config.apiDomain}/api/common/save_broker_details`,
        {
          user_id: localStorage.getItem('userId'),
          broker_client_id: formData.broker_client_id,
          broker_password: formData.broker_password,
          broker_qr_totp_token: formData.broker_qr_totp_token,
          broker_api_key: formData.broker_api_key
        },
        {
          headers: {
            "Authorization": `Bearer ${authToken}`
          }
        }
      );
  
      if (response.data.st === 1) {
        setUserData(formData); // Update userData with the formData
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg, life: 3000 });
      } else {
        if (response.data.msg === 'Token expired') {
          // Handle token expiration
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Session expired. Please log in again.', life: 3000 });
          // Navigate to login component
          navigate('/commonlogin');
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg, life: 3000 });
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  

  const handleConnectionStatus = (status) => {
    // Replace with your logic to update broker connection status
    // Example logic to update userData
    setUserData({
      ...userData,
      broker_conn_status: status,
    });
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <>
      <StudentHeader />
      <Toast ref={toast} position="top-right" />
      <SubHeaderS />
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container flex-grow-1 container-p-y">
              <nav aria-label="breadcrumb">
  <ol className="breadcrumb breadcrumb-style1 text-secondary">
    <li className="breadcrumb-item">
      <Link to="/student/dashboard" className="text-secondary">
        <i className="ri-home-7-line ri-lg"></i>
      </Link>
    </li>
    <li className="breadcrumb-item active text-secondary" aria-current="page">
      Profile
    </li>
  </ol>
</nav>

          
                  <div className="row">
                    <div className="col-12">
                      <div className="card mb-6">
                      <div 
  className="user-profile-header-banner" 
  style={{
    background: 'linear-gradient(90deg, rgba(0,128,255,1) 0%, rgba(0,255,128,1) 100%)',
    height: '164px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  }}>
</div>
                        <div className="user-profile-header d-flex flex-column flex-lg-row text-sm-start text-center mb-4">
                          <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                            <img
                              src={img}
                              alt="user image"
                              className="d-block h-auto ms-0 ms-sm-5 rounded user-profile-img"
                            />
                          </div>
                          <div className="flex-grow-1 mt-3 mt-lg-5">
                            <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-5 flex-md-row flex-column gap-4">
                              <div className="user-profile-info">
                                {userData ? (
                                  <>
                                    <h4 className="mb-2 mt-lg-6"> {capitalizeFirstLetter(userData.name)}</h4>
                                    <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-4">
                                      <li className="list-inline-item">
                                        <i className="ri-user-settings-line  ri-24px"></i>
                                        <span className="fw-medium"> {capitalizeFirstLetter (userData.role)}</span>
                                      </li>
                                      <li className="list-inline-item">
                                        <i className="ri-mobile-download-line  ri-24px"></i>
                                        <span className="fw-medium"> {userData.mobile}</span>
                                      </li>
                                      <li className="list-inline-item">
                                        <i className="ri-wallet-line  ri-24px"></i>
                                        <span className="fw-medium"> Commission: {userData.commission}%</span>
                                      </li>
                                    </ul>
                                  </>
                                ) : (
                                  <p>Loading...</p>
                                )}
                              </div>
                              <div className="ms-auto">
                             
       {userData && (
        <span
          className={`badge bg-success ${userData.broker_conn_status ? "bg-success" : ""}`}
          style={{ fontSize: '14px' }}
        >
          {userData.broker_conn_status && (
            <>
              <i className="ri-shield-check-line me-1"></i>
              Connected
            </>
          )}
        </span>
      )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            <div className="row">
              <div className="col-md-12">
                <div className="nav-align-top">
                  <ul className="nav nav-pills flex-column flex-sm-row mb-6 gap-2 gap-lg-0">
                    <li className="nav-item  active">
                   <Link to="/app2/student_profile"  className="nav-link active btn btn-primary ">
                        <i className="ri-user-3-line me-1_5"></i>Profile
                        </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/student/report"  className="nav-link">
                        <i className="ri-team-line me-1_5"></i>Reports
                        </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/student/login_activity_log"  className="nav-link">
                        <i className="ri-team-line me-1_5"></i>Activity log
                        </Link>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            {userData && (
            <div className="row ">
                          <div className="col-md-3">
                            <div className="card ">
                              <div className="card-body pt-0">
                             
                                <ul className="list-unstyled my-3 py-1">
                               
                                  <li className="d-flex flex-column align-items-start mb-4">
                                 
                                    <span className="fw-medium fs-5">
                                    About
                                    </span>
                                   
                                  </li>
                                  <li className="d-flex justify-content-between align-items-center mb-4">
                                    <strong>Name:</strong>
                                    <span className="ml-auto">
                                    {capitalizeFirstLetter(userData.name)}
                                    </span>
                                  </li>
                                  <li className="d-flex justify-content-between align-items-center mb-4">
                                    <strong>Role:</strong>
                                    <span className="ml-auto">  {capitalizeFirstLetter(userData.role)}</span>
                         
                                  
                                  </li>
                                  <li className="d-flex justify-content-between align-items-center mb-4">
      <strong>Broker Connection:</strong>
      <span className="text-success ml-auto">
        <div className="ms-auto">
          <div
            className={`text-success ml-auto ${
              userData.broker_conn_status ? 'text-success' : 'text-danger'
            }`}
            onClick={() => handleConnectionStatus(!userData.broker_conn_status)}
          >
            {  userData.broker_conn_status? (
              <><i className="ri-shield-check-line"></i> Connected</>
            ) : (
              <><i className="ri-close-large-line"></i> Disconnected</>
            )}
          </div>
        </div>
      </span>
    </li>
                                  <li className="d-flex justify-content-between align-items-center mb-4">
                                    <strong>Commission:</strong>
                                    <span className="ml-auto">{userData.commission}%</span>
                                  </li>
                                  <li className="d-flex justify-content-between align-items-center mb-4">
  <strong>Broker Acc. Balance:</strong>
  <span className="ml-auto">{(userData.amount || 0).toFixed(2)} Rs.</span>
</li>
                                </ul>
                                <hr  />
                                <ul className="list-unstyled my-3 py-1">
                                  <li className="d-flex flex-column align-items-start mb-4">
                                    <span className="fw-medium fs-5">
                                      Contacts
                                    </span>
                                  </li>
                                  <li className="d-flex justify-content-between align-items-center mb-4">
                                    <strong>Email:</strong>
                                    <span className="ml-auto">
                                     {userData.email}
                                    </span>
                                  </li>
                                  <li className="d-flex justify-content-between align-items-center mb-4">
                                    <strong>Mobile:</strong>
                                    <span className="ml-auto">
                                      {userData.mobile}
                                    </span>
                                  </li>
                                 
                                </ul>
                                <hr />
                                <ul className="list-unstyled my-3 py-1">
                                  <li className="d-flex justify-content-between align-items-center mb-4">
                                    <strong>Lot Size Limit:</strong>
                                    <span className="ml-auto ">
                                      {userData.lot_size_limit} Lot
                                    </span>
                                  </li>
                                </ul>
                              </div>
                           
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="card ">
                              <div className="card-body pt-0">
                                <form
                                  id="formAccountSettings"
                                  method="POST"
                                  //   onSubmit={handleFormSubmit}
                                >
                                  <div className="row mt-3">
                                  
                                    <span className="fw-medium fs-5 text-start mb-5">
                                      {" "}
                                      <i className="ri-user-line ri-ms me-1 "></i>
                                      Personal Information
                                    </span>
                                    <div className="col-md-4">
                                <div className="input-group input-group-merge">
                                    <div className="form-floating form-floating-outline">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="name"
                                      name="name"
                                      value={formData.name}
                                      placeholder="Name"
                                      required
                                      onChange={handleChange}
                                   
                                     
                                    />
                                    <label htmlFor="name">
                                      {' '}
                                      <span className="text-danger">* </span> Name{' '}
                                    </label>
                                  </div>
                                </div>
                                </div>
                                <div className="col-md-4">
                                <div className="input-group input-group-merge">
                                    <div className="form-floating form-floating-outline">
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="email"
                                      name="email"
                                      value={formData.email}
                                      placeholder="E-mail"
                                      required
                                      onChange={handleChange}
                                   
                                    />
                                    <label htmlFor="email">
                                      {' '}
                                      <span className="text-danger">* </span>E-mail{' '}
                                    </label>
                                  </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input-group input-group-merge">
                                    <div className="form-floating form-floating-outline">
                                      <input
                                        type="text"
                                        id="mobile"
                                        name="mobile"
                                        className="form-control"
                                        value={formData.mobile}
                                        placeholder="Mobile Number"
                                        required
                                        onChange={handleChange}
                                      />
                                      <label htmlFor="mobile">
                                        <span className="text-danger">* </span>Mobile Number{' '}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                   
                                <div className="col-md-4 mt-5">
      <div className="input-group input-group-merge">
        <div className="form-floating form-floating-outline">
          <input
            className="form-control"
            type="text"
            id="lot_size_limit"
            name="lot_size_limit"
            value={formData.lot_size_limit || ''}
            placeholder="Lot Size Limit"
            required
            onChange={handleChange}
            onClick={() => setIsTradingPowerEditable(true)}
          />
          <label htmlFor="lot_size_limit">
            <span className="text-danger">* </span>Lot Size Limit{' '}
          </label>
          {error && <p className="text-danger">{error}</p>}
        </div>
      </div>
    </div>
                              
                                <div className="mt-6 text-end">
                              
                                <button
                                    onClick={handleSubmit}
                                    className="btn btn-success rounded-pill  text-end me-3"
                                    disabled={loading}
                                  >
                                      {loading ?( <div className="spinner-border me-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                                ) : (
                                  <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                                )}{" "}
                                {loading ? "Saving Data" : "Save Data"}
                                  </button>
                                
                              </div>
                                  </div>
                                  <hr></hr>
                                  <div className="row mb-3">
                                    <h5 className="text-start">
                                      {" "}
                                      <i className="ri-group-line ri-ms me-2"></i>
                                      Broker Information
                                    </h5>

                                    <div className="col-md-4">
                                <div className="input-group input-group-merge">
                                    <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="broker_client_id"
                                      name="broker_client_id"
                                      placeholder="Broker Client ID"
                                      value={formData.broker_client_id }
                                      onChange={handleChange}
                                      disabled={formData.broker_conn_status}
                                      // disabled
                                    />
                                    <label htmlFor="broker_client_id"><span className="text-danger">* </span>Broker Client ID</label>
                                  </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                <div className="input-group input-group-merge">
                                    <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="broker_password"
                                      name="broker_password"
                                      placeholder="Broker Password"
                                      value={formData.broker_password || ''}
                                      onChange={handleChange}
                                      disabled={formData.broker_conn_status}
                                     
                                    />
                                    <label htmlFor="broker_password"><span className="text-danger">* </span>Broker Password</label>
                                  </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                <div className="input-group input-group-merge">
                                    <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="broker_qr_totp_token"
                                      name="broker_qr_totp_token"
                                      value={formData.broker_qr_totp_token}
                                      placeholder="Broker QR TOTP Token"
                                      onChange={handleChange}
                                      disabled={formData.broker_conn_status}
                                    />
                                    <label htmlFor="broker_qr_totp_token"><span className="text-danger">* </span>Broker QR TOTP Token</label>
                                  </div>
                                  </div>
                                </div>
                                    <div className="col-md-4 mt-5">
                                  
                                <div className="input-group input-group-merge">
                                    <div className="form-floating form-floating-outline">
                                    <input
                                      type="text"
                                      className="form-control"
                                     
                                      name="broker_api_key"
                                      placeholder="Broker API Key"
                                      value={formData.broker_api_key}
                                      onChange={handleChange}
                                      autoComplete="broker_api_key"
                                      disabled={formData.broker_conn_status}

                                 

                                    />
                                    <label htmlFor="broker_api_key"><span className="text-danger">* </span>Broker API Key</label>
                                  </div>
                                  </div>
                                </div>
                              
                              </div>
                              {!formData.broker_conn_status && (
                              <div className="mt-6 text-end">
                                
                                 <button
                                    onClick={handleBrokerInformation}
                                    className="btn btn-success rounded-pill  text-end me-3"
                                    disabled={loading}
                                  >
                                      {loading ?( <div className="spinner-border me-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                                ) : (
                                  <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                                )}{" "}
                                {loading ? "Saving Data" : "Save Data"}
                                  </button>
                               
                              </div>
                              )}
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-3">
                            <div className="card  ">
                              <div className="card-body pt-0">
                                <ul className="list-unstyled my-3 py-1">
                                  <li className="d-flex flex-column align-items-start mb-4">
                                    <span className="fw-medium fs-5">
                                      Overview
                                    </span>
                                  </li>
                                  <li className="d-flex flex-column align-items-start mb-4">
                                    <span>Last Login:</span>
                                  </li>
                                  <li className="d-flex flex-column align-items-start mb-4">
                                    <span>Account Created On: </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div> */}
                        </div>
            )}
                      
                   
                  
                
            
          
         
          <Footer></Footer>
        </div>
      </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default Profile;