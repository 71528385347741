import SubHeader from "../component/SubHeader";
import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Modal, Button } from "react-bootstrap";
import config from "../../../component/config";
import img from "../../../assets/img/avatars/1.png";

import { VirtualScroller } from "primereact/virtualscroller";
import Footer from "../../../component/Footer";
const ActivityLog = () => {
  const [loading, setLoading] = useState(false);
  const toast = React.useRef(null);
const navigate=useNavigate();
  const [reportData, setReportData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [activities, setActivities] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const authToken = localStorage.getItem("authToken");
       
        if (!userId) {
          navigate('/commonlogin'); 
          return; 
        }
  
        if (!authToken) {
          console.error("Access token not found in localStorage");
          navigate("/commonlogin")
          return;
        }
  
        const response = await fetch(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${authToken}`
            },
            body: JSON.stringify({ user_id: userId }),
          }
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
  
        if (data.st === 1) {
          setUserData(data.user_details); // Corrected from data.user to data.user_details
      
        } else {
          console.error("Failed to fetch user profile:", data.message);
        }
      }catch (error) {
        console.error("Error fetching user profile:", error);
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate('/commonlogin'); // Navigate to common login screen on 401 error
        }
      }
    };
  
    fetchUserProfile();
  }, []); 
  
  

  const [showPopup, setShowPopup] = useState(false); 
  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();


      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute


    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; 
    } else if (hours === 15) {
      return "danger"; 
    }
    return "secondary"; 
  };



 

  const formatDateTime = (datetime) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString("en-US", options);
  };

  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };

  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString("default", { month: "short" });
  };

  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };

 

  

  const [isLoading, setIsLoading] = useState(false);

  const generateReport = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
     const userId=localStorage.getItem('userId');
      if (!authToken) {
        console.error('Auth token not found in localStorage');
        return;
      }
  
      const response = await axios.post(`${config.apiDomain}/api/common/login_activity_log`, {
        user_id: userId,
      }, {
        headers: {
          'Authorization': `Bearer ${authToken}`, // Include the auth token in the headers
          'Content-Type': 'application/json',
        },
      });
  
      if (response.data.st === 1) {
        setReportData(response.data.activity_list);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Activity log fetched successfully.',
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: response.data.msg,
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      }
    }
  };
  
  const itemTemplate = (item) => {
    return (
      <div className="mb-5">
        <small className={`mb-1 d-inline ${item.Message ? 'text-success' : 'text-danger'}`}>
          <small className="text-start text-muted">Status:</small> {item.Message}
        </small>
        <p className="mb-5 text-start">
          Datetime: {formatDateTime(item.datetime)} {formatDateDay(item.datetime)}-
          {formatDateMonth(item.datetime)}-{formatDateYear(item.datetime)}
        </p>
      </div>
    );
  };


  return (
    <div>
      <Header />
      <SubHeader />
      <Toast ref={toast} />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-style1 text-secondary">
                    <li className="breadcrumb-item">
                      <Link to="/teacher/dashboard" className="text-secondary">
                        <i className="ri-home-7-line ri-lg"></i>
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-secondary"
                      aria-current="page"
                    >
                      Profile
                    </li>
                  </ol>
                </nav>

                <div className="row">
                  <div className="col-12">
                    <div className="card mb-6">
                      <div
                        className="user-profile-header-banner"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(0,128,255,1) 0%, rgba(0,255,128,1) 100%)",
                          height: "164px",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                      ></div>
                      <div className="user-profile-header d-flex flex-column flex-lg-row text-sm-start text-center mb-4">
                        <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                          <img
                            src={img}
                            alt="user image"
                            className="d-block h-auto ms-0 ms-sm-5 rounded user-profile-img"
                          />
                        </div>
                        <div className="flex-grow-1 mt-3 mt-lg-5">
                          <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-5 flex-md-row flex-column gap-4">
                            <div className="user-profile-info">
                              {userData ? (
                                <>
                                  <h4 className="mb-2 mt-lg-6">
                                    {" "}
                                    {capitalizeFirstLetter(userData.name)}
                                  </h4>
                                  <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-4">
                                    <li className="list-inline-item">
                                      <i className="ri-user-settings-line  ri-24px"></i>
                                      <span className="fw-medium">
                                        {" "}
                                        {capitalizeFirstLetter(userData.role)}
                                      </span>
                                    </li>
                                    <li className="list-inline-item">
                                      <i className="ri-mobile-download-line  ri-24px"></i>
                                      <span className="fw-medium">
                                        {" "}
                                        {userData.mobile}
                                      </span>
                                    </li>
                                    <li className="list-inline-item">
                                      <i className="ri-wallet-line  ri-24px"></i>
                                      <span className="fw-medium">
                                        {" "}
                                        Commission: {userData.commission}%
                                      </span>
                                    </li>
                                  </ul>
                                </>
                              ) : (
                                <p>Loading...</p>
                              )}
                            </div>
                            <div className="ms-auto">
                              {userData && (
                                <span
                                  className={`badge bg-success ${
                                    userData.broker_conn_status
                                      ? "bg-success"
                                      : ""
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {userData.broker_conn_status && (
                                    <>
                                      <i className="ri-shield-check-line me-1"></i>
                                      Connected
                                    </>
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="nav-align-top">
                      <ul className="nav nav-pills flex-column flex-sm-row mb-6 gap-2 gap-lg-0">
                        <li className="nav-item ">
                          <Link to="/teacher/user_profile" className="nav-link">
                            <i className="ri-user-3-line me-1_5"></i>Profile
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/teacher/user_profile_report"
                            className="nav-link  "
                          >
                            <i className="ri-team-line me-1_5"></i>Reports
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/teacher/user_profile_Timeline"
                            className="nav-link  "
                          >
                            <i className="ri-team-line me-1_5"></i>Timeline
                          </Link>
                        </li>
  
                        <li className="nav-item">
                          <Link
                            to="/teacher/user_profile_activity_log"
                            className="nav-link active"
                          >
                            <i className="ri-team-line me-1_5"></i>Activity log
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-xl-6 mb-6 mb-xl-0">
                    <div className="card">
                      <div className="d-flex justify-content-between align-items-center card-header">
                        <h5 className="text-center mb-0 flex-grow-1">Activity Log</h5>
                      </div>
                      <div className="card-body">
                        <button className="btn btn-primary active me-3 mb-3" onClick={generateReport}>
                          Activity Log
                        </button>
                        
                          <div style={{ height: '400px', overflowY: 'auto' }}>
                            <VirtualScroller
                              items={reportData}
                              itemSize={50}
                              itemTemplate={itemTemplate}
                              className="custom-scrollbar"
                            />
                          </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
