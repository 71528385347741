import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";


import StudentMyReport from "./src/app2/Student/StudentMyReport";
import StudentMyReportView from "./src/app2/Student/StudentMyReportView";
import StudentProfile from "./src/app2/Student/Student_profile";
import Dashboard from "./src/app2/Student/Dashboard";
import StudentOrderBook from "./src/app2/Student/StudentOrderBook";
import StudentTradeBook from "./src/app2/Student/StudentTradeBook";

import AdminDashboard from "./src/app3/Admin/AdminDashboard";
import ManageTeacher from "./src/app3/Admin/ManageTeacher";
import CreateTeacher from "./src/app3/Admin/CreateTeacher";
import UpdateTeacher from "./src/app3/Admin/UpdateTeacher";
import ViewTeacher from "./src/app3/Admin/ViewTeacher";
import AdminProfile from "./src/app3/Admin/AdminProfile";
import AdminMyReport from "./src/app3/Admin/AdminMyReport";
import AdminMyReportDetails from "./src/app3/Admin/AdminMyReportDetails";
import TeacherList from "./src/app3/Admin/TeacherList";
import TeacherReport from "./src/app3/Admin/TeacherReport";
import TeacherReportDetails from "./src/app3/Admin/TeacherReportDetails";




import Home from "../src/src/app1/screen/Home";
import Basket from "../src/src/app1/screen/Basket";


import TradeBook from "../src/src/app1/screen/Tradebook";
import OrderBook from "../src/src/app1/screen/OrderBook";

import StudentReport from "../src/src/app1/screen/StudentReport";
import MyReport from "../src/src/app1/screen/MyReport";
import MyReportView from "../src/src/app1/screen/MyReportView";
import StudReportList from "../src/src/app1/screen/StudReportList";

import TimeLine from "../src/src/app1/screen/TimeLine";
import Position from "../src/src/app1/screen/Position";
import ManageStudent from "../src/src/app1/screen/ManageStudent";
import CreateStudent from "../src/src/app1/screen/CreateStudent";
import UpdateStudent from "../src/src/app1/screen/UpdateStudent";
import StudentDetails from "../src/src/app1/screen/StudentDetails";
import Reports from "./src/app3/Admin/Reports";
import ProfileReports from "./src/app2/Student/ProfileReport";
import TeacherProfile from "./src/app1/screen/Profile";
import TeacherProfileReport from "./src/app1/screen/TeacherProfileRrport";
import StudentReportDetails from "../src/src/app1/screen/StudentDetails";
import ViewStudent from "./src/app1/screen/ViewStudent";
import OrderbookDetails from "./src/app1/screen/OrderbookDetails";
import StudentOrderDetails from "./src/app2/Student/StudentOrderDetails";
import AdminOrderBook from "./src/app3/Admin/AdminOrderBook";
import AdminOrderBookDetails from "./src/app3/Admin/AdminOrderBookDetails";
import AdminTradeBook from "./src/app3/Admin/AdminTradeBook";

import CommonLogin from "./component/CommonLogin";
// import StudentOrderDetails from "./src/app1/Student/StudentOrderDetails";
import ActivityLog from "./src/app1/screen/ActivityLog";
import AdminLoginActivityLog from "./src/app3/Admin/AdminLoginActivityLog"
import LoginActivityLog from "./src/app2/Student/LoginActivityLog";
import TeacherReportDatewise from "./src/app3/Admin/TeacherReportDatewise";
import MyReportDatewise from "./src/app1/screen/MyReportDatewise";
function App() {
  return (

      <Routes>
    
 <Route path="/" element={<CommonLogin/>} />

 <Route path= "/student/login_activity_log" element={<LoginActivityLog/>} />
 <Route path="/admin/login_activity_log" element={<AdminLoginActivityLog/>} />

        <Route path="/student/dashboard" element={<Dashboard />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/manage_teacher" element={<ManageTeacher />} />
        <Route path="/admin/manage_teacher/create_teacher" element={<CreateTeacher />} />
        
        <Route path="/admin/manage_teacher/view_teacher/:id" element={<ViewTeacher />} />
        <Route path="/admin/profile" element={<AdminProfile />} />
        <Route path="/admin/report" element={<Reports />} />
        <Route path="/admin/my_report" element={<AdminMyReport />} />
        <Route path="/admin/order_book" element={<AdminOrderBook />} />
        <Route path="/admin/order_book/order_details" element={<AdminOrderBookDetails />} />
        <Route path="/admin/trade_book" element={<AdminTradeBook />} />

        <Route path="/admin/manage_teacher/update_teacher/:id" element={<UpdateTeacher />} />
        <Route
          path="/admin/report_details"
          element={<AdminMyReportDetails />}
        />
        <Route path="/admin/teacher_list" element={<TeacherList />} />
        {/* <Route path="/admin/teacher_report" element={<TeacherReport />} /> */}
        <Route path="/admin/teacher_report/:teacherId" element={<TeacherReport />} />

        {/* <Route
          path="/admin/teacher_report_details"
          element={<TeacherReportDetails />}
        /> */}
                <Route path="/admin/teacher_report_details/:teacherId/:monthName" element={<TeacherReportDetails />} />
                <Route path="/admin/teacher_report_date_wise/:teacherId/:monthName" element={<TeacherReportDatewise />} />

   {/* <Route path="/app2/student_order_details" element={<StudentOrderDetails />} /> */}
        <Route path="/app2/student_my_report" element={<StudentMyReport />} />
        <Route path="/app2/student_profile" element={<StudentProfile />} />

        <Route
          path="/app2/student_report_view"
          element={<StudentMyReportView />}
        />
        <Route path="/app2/order_book" element={<StudentOrderBook />} />
        <Route path="/app2/student_trade_book" element={<StudentTradeBook />} />
        <Route path="/student/report" element={<ProfileReports />} />
        <Route path="/app2/student_order_details/:uniqueorderid" element={<StudentOrderDetails />} />
        {/* Teacher dashboard */}
         <Route path="/teacher/dashboard" element={<Home />} />
        <Route path="/home" element={<Home />} />
        {/* <Route path="/user_profile" element={<Profile />} /> */}
        <Route path="/teacher/user_profile" element={<TeacherProfile />} />
        <Route path="/teacher/user_profile_report" element={<TeacherProfileReport />} />
      
        <Route path="/user_basket" element={<Basket />} />
        <Route path="/trade_book" element={<TradeBook />} />
        <Route path="/trade_position" element={<Position />} />
        <Route path="/order_book" element={<OrderBook />} />
       
      
        <Route path="/order_book/orderbook_details/:uniqueorderid" element={<OrderbookDetails />} />

        <Route path="/teacher/my_report" element={<MyReport />} />
        <Route path="/teacher/my_report_datewise" element={<MyReportDatewise />} />
        <Route path="/teacher/my_report_view" element={<MyReportView />} />
        <Route path="/teacher/student_report" element={<StudentReport />} />
        {/* <Route path="/teacher/student_report_list" element={<StudReportList />} /> */}
        <Route path="/teacher/student_report_list/:userId/:month_name" element={<StudReportList />} />
        <Route path="/teacher/student_report_details" element={<StudentReportDetails />} />
        {/* <Route path="/teacher/student_report_details/:teacherId" element={<StudentReportDetails />} /> */}
        {/* <Route path="/teacher/student_report_details/:userId/:month_name" element={<StudentReportDetails />} /> */}
        <Route path="/teacher/student_report_details/:userId" element={<StudentReportDetails />} />

        <Route path="/teacher/user_profile_Timeline" element={<TimeLine />} />
        <Route path="/teacher/manage_student" element={<ManageStudent />} />
        <Route path="/teacher/manage_student/create_student" element={<CreateStudent />} />
        <Route path="/teacher/manage_student/update_student/:id" element={<UpdateStudent />} />
        <Route path="/teacher/student_details" element={<StudentDetails />} />
        {/* <Route path="/teacher/view_student" element={<ViewStudent />} /> */}
        <Route path="/teacher/manage_student/view_student/:id" element={<ViewStudent />} />

    <Route path="/teacher/user_profile_activity_log" element={<ActivityLog/>}></Route>
 




       

<Route path="/commonlogin" element={<CommonLogin/>} />

      </Routes>
    
  );
}

export default App;
