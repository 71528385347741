import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { classNames } from 'primereact/utils';
const ManageTeacher = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useRef(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);



const handleToggleTrade = async (userId) => {
  try {
    const authToken = localStorage.getItem("authToken");

    if (!authToken) {
      throw new Error("Auth token not found");
    }

    const response = await axios.post(
      `${config.apiDomain}/api/admin/teacher_trade_switch`,
      { teacher_id: userId },
      { headers: { Authorization: `Bearer ${authToken}` } }
    );

    console.log("Trade status switch response:", response.data);

    if (response.data.st === 1) {

      setData((prevData) =>
        prevData.map((data) =>
          data.user_id === userId
            ? { ...data, trade_status: !data.trade_status }
            : data
        )
      );
     
   
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Trade status updated successfully",
        life: 3000,
      });
    }
  } catch (error) {
    console.error("Error switching trade status:", error);
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized access detected. Redirecting to login...");
      navigate("/commonlogin");
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to switch trade status",
        life: 3000,
      });
    }
  }
};





const handleToggle = async (teacherId) => {
  try {
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage

    if (!authToken) {
      throw new Error("Auth token not found");
    }

    const response = await fetch(
      `${config.apiDomain}/api/admin/teacher_active_switch`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, 
        },
        body: JSON.stringify({ teacher_id: teacherId }),
      }
    );
    const responseData = await response.json();

    if (response.ok && responseData.st === 1) {
      const updatedData = data.map((item) => {
        if (item.user_id === teacherId) {
          return {
            ...item,
            active_status: !item.active_status,
          };
        }
        return item;
      });

      setData(updatedData);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Teacher status toggled successfully",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: responseData.msg || "Failed to toggle teacher status",
        life: 3000,
      });
    }
  } 
catch (error) {
  console.error('Error fetching data:', error);
  // Check for 401 Unauthorized error
  if (error.response && error.response.status === 401) {
    console.error('Unauthorized access, redirecting to common login screen');
    navigate('/commonlogin'); // Navigate to common login screen on 401 error
  } else {
    const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
    setError(new Error(errorMsg));
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: errorMsg,
      life: 3000,
    });
  }
}
};




const fetchData = async () => {
  setLoading(true);
  setError(null);

  try {
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage

    if (!authToken) {
     navigate("/commonlogin");
     return;
    }

    const response = await axios.get(
      `${config.apiDomain}/api/admin/manage_teachers/listview`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
        },
      }
    );

    if (response.data && response.data.st === 1) {
      // Assuming each item in the data array has a timestamp or ID to sort by
      const sortedData = response.data.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setData(sortedData);
    } else {
      const errorMsg = response.data.msg || "Failed to fetch data";
      setError(new Error(errorMsg));
    }
  }catch (error) {
    console.error('Error fetching data:', error);
    // Check for 401 Unauthorized error
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized access, redirecting to common login screen');
      navigate('/commonlogin'); // Navigate to common login screen on 401 error
    } else {
      const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
      setError(new Error(errorMsg));
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMsg,
        life: 3000,
      });
    }
  } finally {
    setLoading(false);
  }
};






const handleRefresh = async () => {
  setLoading(true);
  setError(null);

  try {
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage

    if (!authToken) {
      throw new Error("Auth token not found");
    }

    const response = await axios.get(
      `${config.apiDomain}/api/admin/manage_teachers/listview`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
        },
      }
    );

    if (response.data && response.data.st === 1) {
      const sortedData = response.data.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      const errorMsg = response.data.msg || "Success: Server Error";
      setError(new Error(errorMsg));
      setData(sortedData);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: toTitleCase(errorMsg),
        life: 3000,
      });
    } else if (response.data && response.data.st === 2) {
      const errorMsg = response.data.msg || "Warning";
      setError(new Error(errorMsg));
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: toTitleCase(errorMsg),
        life: 3000,
      });
    } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
      const errorMsg = response.data.msg || "Danger: Server Error";
      setError(new Error(errorMsg));
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: toTitleCase(errorMsg),
        life: 3000,
      });
    } else {
      const errorMsg = response.data.msg || "Failed to fetch data";
      setError(new Error(errorMsg));
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: toTitleCase(errorMsg),
        life: 3000,
      });
    }
  }catch (error) {
    console.error('Error fetching data:', error);
    // Check for 401 Unauthorized error
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized access, redirecting to common login screen');
      navigate('/commonlogin'); // Navigate to common login screen on 401 error
    } else {
      const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
      setError(new Error(errorMsg));
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMsg,
        life: 3000,
      });
    }
  } finally {
    setLoading(false);
  }
};


const handleDelete = async () => {
  try {
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage

    if (!authToken) {
      throw new Error("Auth token not found");
    }

    const response = await fetch(
      `${config.apiDomain}/api/teacher/manage_students/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
        },
        body: JSON.stringify({ student_id: selectedRow.user_id }), // Use selectedRow for deletion
      }
    );
    const responseData = await response.json();

    if (response.ok && responseData.st === 1) {
      // Update the data state by removing the deleted student
      const updatedData = data.filter(
        (item) => item.user_id !== selectedRow.user_id
      );
      setData(updatedData);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: responseData.msg || "Student deleted successfully",
        life: 3000,
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: responseData.msg || "Failed to delete student",
        life: 3000,
      });
    }
  } catch (error) {
    console.error("Network error", error);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: error.message || "Network error",
      life: 3000,
    });
  } finally {
    // Close the modal after action
    setShowConfirmModal(false);
  }
};

const handleDeleteClick = (rowData) => {
  setSelectedRow(rowData); // Save the row to be deleted
  setShowConfirmModal(true); // Show the confirmation modal
};


  useEffect(() => {
    fetchData();
  }, []);
  const nameBodyTemplate = (rowData) => {
    return <span>{toTitleCase(rowData.name)}</span>;
  };
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const lotSizeBodyTemplate = (rowData) => {
    return `${rowData.lot_size_limit} Lot`;
  };
  
  // Custom render function for commission
  const commissionBodyTemplate = (rowData) => {
    return `${rowData.commission}%`;
  };
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  return (
    <>
          <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />

      <div className="container-xxl container-p-y" align="center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-secondary">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-secondary">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>

            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Manage Teacher
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-center mb-5">
              <h5 className="mb-0">Manage Teacher</h5>
            </div>
            <div className="col text-end mb-5">
              <Link to="/admin/manage_teacher/create_teacher">
                <button className="btn btn-success">
                  <i className="ri-add-circle-line ri-lg me-1"> </i>
                  <span>Create Teacher</span>
                </button>
              </Link>
            </div>
          </div>

          <div className="d-flex justify-content-end mb-3">
            {loading ? (
                                               <div className="d-flex align-items-center">
                                               <div
                                                 className="spinner-border spinner-border-sm custom-spinner me-3"
                                                 role="status"
                                                 
                                               >
                                                 <span className="visually-hidden">Loading...</span>
                                               </div>
                                             </div>
                                           ) : (
                                             <div className="mt-3">
                                               <Tooltip target=".custom-target-icon" />
                                               <i
                                                 className="custom-target-icon ri ri-refresh-line ri-lg me-3 p-text-secondary" role="button"
                                                 data-pr-tooltip="Reload"
                                                 onClick={handleRefresh}
                                                 data-pr-position="top"
                                                
                                               ></i>
                                             </div>
            )}
          
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line "></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
          </div>
          <DataTable
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
            style={{ border: "1px solid #ddd",  }}
             className="p-datatable-rounded"
            
             body={nameBodyTemplate}
          >
            <Column
              align={"center"}
              style={{ border: "1px solid #ddd" }}
              field="name"
              header="Name"
             
              body={nameBodyTemplate}
            ></Column>
            <Column
              align={"center"}
              style={{ border: "1px solid #ddd" }}
              field="mobile"
              header="Mobile"
            ></Column>
             <Column
              align={"center"}
              style={{ border: "1px solid #ddd" }}
              field="lot_size_limit"
              header="Lot Size Limit"
              body={lotSizeBodyTemplate}
            ></Column>
             <Column
              align={"center"}
              style={{ border: "1px solid #ddd" }}
              field="commission"
              header="Commission"
              body={commissionBodyTemplate}

            ></Column>
           
            <Column
  align={"center"}
  style={{ border: "1px solid #ddd" }}
  header="Broker Conn. Status"
  body={(rowData) => (
    <div className={classNames({
      'text-success': rowData.broker_status,
      'text-danger': !rowData.broker_status
    })}>
      {rowData.broker_status ? (
        <>
          <i className="ri-shield-check-line"></i> Connected
        </>
      ) : (
        <>
          <i className="ri-close-large-line"></i> Disconnected
        </>
      )}
    </div>
  )}
/>
<Column
  align="center"
  style={{ border: "1px solid #ddd" }}
  header="Account Status"
  body={(rowData) => (
    <button
      className={`btn rounded-pill btn-xs ${rowData.active_status
        ? "btn-outline-success"
        : "btn-outline-danger"
        } waves-effect`}
      onClick={() => handleToggle(rowData.user_id)}
    >
      {rowData.active_status ? "Active" : "Inactive"}
    </button>
  )}
/>

<Column
          align="center"
          style={{ border: "1px solid #ddd" }}
          header="Trade Status"
          body={(rowData) => (
            <button
              className={`btn rounded-pill btn-xs ${
                rowData.trade_status ? "btn-outline-success" : "btn-outline-danger"
              } waves-effect`}
              onClick={() => handleToggleTrade(rowData.user_id)}
            >
              {rowData.trade_status ? "Active" : "Inactive"}
            </button>
          )}
        />
            <Column
              align={"center"}
              style={{ border: "1px solid #ddd" }}
              header="Actions"
              body={(rowData) => (
                <>
                  <Link
                    to={`/admin/manage_teacher/view_teacher/${rowData.user_id}`}
                    state={{ teacherId: rowData.user_id }}
                  >
                    <button className="btn btn-primary me-3 custom-btn-action1 custom-target-icon"   data-pr-tooltip="View Record"
                    data-pr-position="top">
                      <i className="ri-timeline-view ri-lg"></i>
                    </button>
                  </Link>
                  <Link
                    to={`/admin/manage_teacher/update_teacher/${rowData.user_id}`}
                    state={{ teacherId: rowData.user_id }}
                  >
                  <button
                    className="btn btn-info me-3 custom-btn-action1 custom-target-icon"
                    // onClick={() => handleEdit(rowData)}
                      data-pr-tooltip="Update Record"
                    data-pr-position="top"
                  >
                    <i className="ri-edit-line ri-lg"></i>
                  </button>
</Link>
                  <button
                    className="btn btn-danger active text-align custom-btn-action1 custom-target-icon"
                    onClick={() => handleDeleteClick(rowData)} 
                      data-pr-tooltip="Delete Record"
                    data-pr-position="top"
                  >
                    <i className="ri-close-line ri-lg"></i>
                  </button>
                </>
              )}
            ></Column>
            
          </DataTable>

        </div>
      </div>
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this student?</Modal.Body>
        <Modal.Footer>
         
          <div className="d-flex justify-content-between w-100">
                            <button
                            onClick={() => setShowConfirmModal(false)}
                              className="btn btn-outline-secondary rounded-pill btn-sm "
                            >
                              <i className="ri-close-large-line me-2"></i>
                              <span>Cancel</span>
                            </button>
                            <button
                            onClick={handleDelete}
                              
                              className="btn btn-danger rounded-pill btn-sm"
                            >
                              <i className="ri-delete-bin-line me-2"></i>
                              Delete
                            </button>
                          </div>

        </Modal.Footer>
      </Modal>
      <Footer />
    </>
  );
};

export default ManageTeacher;
