
import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../component/Header";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";
import config from "../../../component/config";

import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component
  const [studentCount, setStudentCount] = useState(null);
const navigate=useNavigate();
  useEffect(() => {
    const fetchTeacherHomeData = async () => {
      const userId = localStorage.getItem("userId"); // Assuming you have stored teacher_id in localStorage
      if (!userId) {
       navigate("/commonlogin");
        return; 
      }
  
      try {
        const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
  
        if (!authToken) {
          throw new Error("Auth token not found");
        }
  
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/teacher_home`,
          {
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
            },
          }
        );
  
        if (response.data.st === 1) {
          setStudentCount(response.data.student_count);
        } else {
          console.error("Failed to fetch teacher home data:", response.data.msg);
        }
      }  catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate('/commonlogin'); // Navigate to common login screen on 401 error
        } else {
          console.error("Error fetching teacher home data:", error);
        }
      }
    };
  
    fetchTeacherHomeData();
  }, []);
  
  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };

  return (
    <div>
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row g-6">
              <div className="col-sm-6 col-lg-2">
                <div className="card card-border-shadow-primary h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2">
                      <div className="avatar me-4">
                        <span className="avatar-initial rounded bg-label-primary">
                          <i className="ri-group-3-line ri-24px"></i>
                        </span>
                      </div>
                      <h4 className="mb-0 fs-3">{studentCount}</h4>
                    </div>
                    <h6 className="mb-0 fw-normal">No of Students</h6>
                  </div>
                </div>
              </div>
              {/* Add more cards or components for other data */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};


// import React, { useState } from "react";
// import { Container, Form, Row, Col, Nav, Button, Card } from "react-bootstrap";
// import axios from "axios";
// import config from "../../../component/config";

// function Home() {
//   const [search, setSearch] = useState("");
//   const [equityData, setEquityData] = useState([]);
//   const [commodityData, setCommodityData] = useState([]);
//   const [fnoData, setFnoData] = useState([]);
//   const [selectedStock, setSelectedStock] = useState(null);
//   const [selectedExchange, setSelectedExchange] = useState("Equity");
//   const [showNav, setShowNav] = useState(true);
//   const [showDropdown, setShowDropdown] = useState(false); // Manage dropdown visibility

//   const userId = localStorage.getItem("userId");
//   const authToken = localStorage.getItem("authToken");

//   const handleSearch = async (e) => {
//     const searchValue = e.target.value.toLowerCase();
//     setSearch(searchValue);

//     if (searchValue.trim() !== "") {
//       try {
//         const response = await axios.post(
//           `${config.apiDomain}/api/teacher/get_instrument_list_new`,
//           {
//             search: searchValue,
//             teacher_id: userId,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${authToken}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         if (response.data.st === 1) {
//           setEquityData(response.data.data.equity || []);
//           setCommodityData(response.data.data.commodity || []);
//           setFnoData(response.data.data.fno || []);
//           setShowDropdown(true); // Show dropdown when search results are available
//         } else {
//           setEquityData([]);
//           setFnoData([]);
//           setCommodityData([]);
//           setShowDropdown(false);
//         }
//       } catch (error) {
//         console.error("Error fetching instrument list", error);
//       }
//     } else {
//       setEquityData([]);
//       setFnoData([]);
//       setCommodityData([]);
//       setShowDropdown(false);
//     }
//   };

//   const handleStockSelect = (stock) => {
//     setSelectedStock(stock);
//     setSearch(stock.symbol);
//     setShowNav(false);
//     setShowDropdown(false); // Hide dropdown when a stock is selected
//   };

//   const handleStockDeselect = () => {
//     setSelectedStock(null);
//     setSearch("");
//     setShowNav(true);
//     setShowDropdown(true); // Show dropdown when stock is deselected
//   };

//   const handleExchangeSelect = (exchange) => {
//     setSelectedExchange(exchange);
//   };

//   const filteredStocks = (() => {
//     switch (selectedExchange) {
//       case "Equity":
//         return equityData;
//       case "FnO":
//         return fnoData;
//       case "Commodity":
//         return commodityData;
//       default:
//         return [...equityData, ...fnoData, ...commodityData];
//     }
//   })();

//   const getDisplayType = (exchSeg) => {
//     switch (exchSeg) {
//       case "EQ":
//         return "EQ";
//       case "FnO":
//         return "FnO";
//       case "CO":
//         return "CO";
//       default:
//         return exchSeg;
//     }
//   };

//   const displayedStocks = filteredStocks.slice(0, 6);

//   return (
//     <Container className="w-25">
//       <Form>
//         <Form.Group>
//           <Form.Control
//             type="text"
//             placeholder="Search stocks"
//             value={search}
//             onChange={handleSearch}
//             className="bg-white"
//           />
//         </Form.Group>
//       </Form>

//       {search && filteredStocks.length > 0 && showNav && (
//         <Nav justify variant="tabs" className="mt-3 bg-white">
//           <Nav.Item>
//             <Nav.Link
//               className={`text-black custom-nav-link ${
//                 selectedExchange === "Equity" ? "active" : ""
//               }`}
//               onClick={() => handleExchangeSelect("Equity")}
//             >
//               <i className="ri-cash-line ri-lg me-1"></i> Equity
//             </Nav.Link>
//           </Nav.Item>
//           <Nav.Item>
//             <Nav.Link
//               className={`text-black custom-nav-link ${
//                 selectedExchange === "FnO" ? "active" : ""
//               }`}
//               onClick={() => handleExchangeSelect("FnO")}
//             >
//               <i className="ri-bar-chart-2-line ri-lg me-1"></i> FnO
//             </Nav.Link>
//           </Nav.Item>
//           <Nav.Item>
//             <Nav.Link
//               className={`text-black custom-nav-link ${
//                 selectedExchange === "Commodity" ? "active" : ""
//               }`}
//               onClick={() => handleExchangeSelect("Commodity")}
//             >
//               <i className="ri-vip-diamond-line ri-lg me-1"></i> Commodity
//             </Nav.Link>
//           </Nav.Item>
//         </Nav>
//       )}

//       {showDropdown && search && filteredStocks.length > 0 && (
//         <Card className="mt-3">
//           <Card.Body className="bg-white" style={{ maxHeight: '300px', overflowY: 'auto' }}>
//             <Row>
//               {displayedStocks.map((stock, index) => (
//                 <Col
//                   key={index}
//                   xs={12}
//                   className={`d-flex align-items-center mb-2 ${
//                     selectedStock === stock ? "bg-body text-black" : ""
//                   }`}
//                   style={{ cursor: "pointer" }}
//                   onClick={() => handleStockSelect(stock)}
//                 >
//                   <div className="d-flex justify-content-center align-items-center border border-primary border-2 rounded-circle bg-label-primary avatar-md me-3">
//                     <span className="avatar-initial rounded-circle bg-label-primary">
//                       {getDisplayType(stock.exch_seg)}
//                     </span>
//                   </div>
//                   <div>
//                     <strong>{stock.symbol}</strong>
//                     <span className="badge rounded-pill bg-label-secondary ms-1">
//                       {stock.exch_seg}
//                     </span>
//                   </div>
//                   <Button
//                     variant="badge badge-center bg-lg rounded-pill bg-label-primary"
//                     className="ms-auto"
//                   >
//                     <i className="ri-add-large-line ri-md"></i>
//                   </Button>
//                 </Col>
//               ))}
//             </Row>
//           </Card.Body>
//         </Card>
//       )}

     
//     </Container>
//   );
// }

// export default Home;



