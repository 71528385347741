
const config = {
    apiDomain: 'https://apasset.xyz',
  };
  
  export default config;


  // const config = {
  //   apiDomain: 'https://ghanish.in',
  // };
  
  // export default config;