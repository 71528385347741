import React, { useEffect, useState ,useRef} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap"; 
import { ProgressSpinner } from "primereact/progressspinner";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import config from "../../../component/config";
import Footer from "../../../component/Footer"
import { Tooltip } from 'primereact/tooltip';
import { Toast } from "primereact/toast";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
const MyReportView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [month, setMonth] = useState("");
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

  useEffect(() => {
    const { state } = location;

    // Check if required data is present in location state
    if (!state || !state.userId || !state.month) {
      // Handle the case where location state is not properly set
      // You can navigate back or handle it based on your application flow
      navigate(-1); // Navigate back
      return;
    }
    setMonth(state.month);
    fetchData(state.userId, state.month);
  }, [location, navigate]);

  const fetchData = async (userId, month) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/trade_details`,
        {
          user_id: userId,
          sell_date: month,
        }
      );

      if (response.data) {
        setData(response.data.trades);
        setSummary(response.data.completed_trades_aggregate);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      setError(new Error(error.message || "Failed to fetch data"));
    } finally {
      setLoading(false);
    }
  };


  const handleRefresh = async () => {
    setLoading(true);
    setError(null);

    try {
      const { userId, month } = location.state;
      const response = await axios.post(
        `${config.apiDomain}/api/common/trade_details`,
        {
          user_id: userId,
          sell_date: month,
        }
      );

      if (response.data) {
        setData(response.data.trades);
        setSummary(response.data.completed_trades_aggregate);
        const errorMsg = response.data.msg || "Success";
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
     
       } else if (response.data && response.data.st === 2) {
          const errorMsg = response.data.msg || "Warning";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "warn",
            summary: "Warning",
            detail: toTitleCase(errorMsg),
            life: 3000,
          });
        } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
          const errorMsg = response.data.msg || "Danger: Server Error";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: toTitleCase(errorMsg),
            life: 3000,
          });
        } else {
          const errorMsg = response.data.msg || "Failed to fetch data";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: toTitleCase(errorMsg),
            life: 3000,
          });
        }
      
      } catch (error) {
        const errorMsg = error.response ? error.response.data.msg || "Failed to fetch data" : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      }  finally {
        setLoading(false);
      }
    };

 
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

 

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

 

 

  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };


  const buyPriceTemplate = (rowData) => {
    return ` ${rowData.buy_price} Rs.`;
  };

  const sellPriceBodyTemplate = (rowData) => {
    return ` ${rowData.sell_price} Rs.`;
  };

  const PLTemplate = (rowData) => {
    return ` ${rowData.pandl} Rs.`;
  };

  const PLTotalBodyTemplate = (rowData) => {
    return ` ${rowData.pandl_total} Rs.`;
  };

  const PLPercentTemplate = (rowData) => {
    return ` ${rowData.pandl_percent} %`;
  };

 

  const formatDateTime = (datetime) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString('en-US', options);
  };
  
  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };
  
  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString('default', { month: 'short' });
  };
  
  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };
  const buyColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">Price: <strong>{rowData.buy_price} </strong> | Lot:<strong>  {rowData.buy_lotsize} ({rowData.buy_stock_quantity})</strong></div>
        <div className="mb-1">{formatDateDay(rowData.buy_datetime)}-{formatDateMonth(rowData.buy_datetime)}-{formatDateYear(rowData.buy_datetime)} {formatDateTime(rowData.buy_datetime)} 
        </div>
        <div > {rowData.buy_orderid}</div>
      </div>
    );
  };
  
  const sellColumnTemplate = (rowData) => {
    return (
      <div >
        <div className="mb-1">Price: <strong>{rowData.sell_price} </strong>  | Lot:<strong> {rowData.sell_lotsize} ({rowData.sell_stock_quantity})</strong></div>
        <div className="mb-1">{formatDateDay(rowData.sell_datetime)}-{formatDateMonth(rowData.sell_datetime)}-{formatDateYear(rowData.sell_datetime)} {formatDateTime(rowData.sell_datetime)} </div>
        <div > {rowData.sell_orderid}</div>
      </div>
    );
  };
  return (
    <>
    <Header></Header>
    <SubHeader></SubHeader>

      <Toast ref={toast} />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-secondary">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-secondary">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/user_profile" className="text-secondary">
                Profile
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/my_report" className="text-secondary">
                My Report
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/my_report_datewise" className="text-secondary">
               Datewise Report
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              My Report Details
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col text-start mb-5 ">
            <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-start mb-5">
              <h5 className="mb-0">My Report Details</h5>
            </div>
          </div>
          

          <div className="d-flex justify-content-between align-items-center mb-3">
  <div className="d-flex align-items-center">
    <IconField iconPosition="left">
      <InputIcon className="ri ri-search-line"></InputIcon>
      <InputText
        type="search"
        placeholder="Search"
        value={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="rounded"
      />
    </IconField>

    {loading ? (
                  <div className="d-flex align-items-center">
                  <div
                    className="spinner-border spinner-border-sm custom-spinner me-3"
                    role="status"
                    
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="mt-3">
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon ri ri-refresh-line ri-lg me-3 p-text-secondary" role="button"
                    data-pr-tooltip="Reload"
                    onClick={handleRefresh}
                    data-pr-position="top"
                   
                  ></i>
                </div>
    )}
  </div>

  <strong className="mt-3 ms-auto  fs-4">{month}</strong> {/* Display month on the right */}
</div>
          <DataTable
            style={{ border: "1px solid #ddd" }}
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
                               <Column
    align="left"
  
      style={{ border: "1px solid #ddd" }}
    header="Buy"
    body={buyColumnTemplate}
  ></Column>

  <Column
    align="left"
    style={{ border: "1px solid #ddd" }}
  
    header="Sell"
    body={sellColumnTemplate}
  ></Column>
            
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl"
              header="P&L"
              body={PLTemplate}
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl_total"
              header="P&L Total"
              body={ PLTotalBodyTemplate} 
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl_percent"
              header="P&L Percent"
              body={PLPercentTemplate}
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="commission"
              header="Commission "
              body={CommissionBodyTemplate}
            ></Column>
          </DataTable>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyReportView;


const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};