import React, { useState, useEffect ,useRef} from "react";
import AdminSubHeader from "./AdminSubHeader";
import Footer from "../../../component/Footer"
import config from "../../../component/config";
import AdminHeader from "./AdminHeader";
import { Toast } from 'primereact/toast';
import { Link,useNavigate } from "react-router-dom";
const AdminDashboard = () => {
  const [message, setMessage] = useState("");
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [teacherCount, setTeacherCount] = useState(0); // State to store teacher count
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
  
        if (!authToken) {
         navigate("/commonlogin");
         return;
        }
  
        const response = await fetch(
          `${config.apiDomain}/api/admin/admin_home`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${authToken}`
            },
          }
        );
  
        if (!response.ok) {
          if (response.status === 401) {
            console.log("Token expired. Redirecting to commonlogin screen...");
           
            navigate("/commonlogin");
            return;
          }
          throw new Error("Network response was not ok");
        }
  
        const data = await response.json();
        setMessage(data.msg); 
        setTeacherCount(data.teacher_count); 
      }catch (error) {
        console.error('Error fetching data:', error);
        
        if (error.response && error.response.status === 401) {
          console.error('Unauthorized access, redirecting to common login screen');
          navigate('/commonlogin'); 
        } else {
          const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
          setError(new Error(errorMsg));
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorMsg,
            life: 3000,
          });
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []); 
  

  return (
    <>
       <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row g-6">
              <div className="col-sm-6 col-lg-2">
                <div className="card card-border-shadow-primary h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2">
                      <div className="avatar me-4">
                        <span className="avatar-initial rounded bg-label-primary">
                          <i className="ri-group-3-line ri-24px"></i>
                        </span>
                      </div>
                      <h4 className="mb-0 fs-3">{teacherCount}</h4>
                    </div>
                    <h6 className="mb-0 fw-normal">No of Teachers</h6>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminDashboard;
